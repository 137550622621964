// import { useEffect, useState } from 'react'
// import { postPatentId } from '../../utilities/api/patentData'
import { Link, useParams } from 'react-router-dom'
import DocumentFeatureItem from '../DocumentFeatureItem/DocumentFeatureItem'
import './DocumentFeature.css'
import { useEffect, useState } from 'react'
import { createReferenceNote, deleteReferenceNote, updateReferenceNote } from '../../utilities/api/referenceNotes'
import { capitalizeFirstLetter } from '../../utilities/services/capitalizeFirstLetter'
import { formNotificationStyle2 } from '../../utilities/services/formNotification'

export default function DocumentFeature({user, project, documentFeature, referenceNotes, num, removeTextAnnotation, removeImageAnnotation, removeReferenceDocuments, updateProject}) {
  // const [documentData, setDocumentData] = useState('')
  const defaultFormData = {
    classification: documentFeature.features? documentFeature.features[0].classification : 'PD',
    projectNumber: project.projNum || '',
    document: documentFeature.documentNumber || '',
    documentPublicationDate: documentFeature.features? documentFeature.features[0].documentPublicationDate : 'null',
    documentTitle: documentFeature.features? documentFeature.features[0].documentTitle : 'null',
  }
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [changesMade, setChangesMade] = useState(false)
  const [referenceNote, setReferenceNote] = useState()
  const [formData, setFormData] = useState()
  const [imageAnnotationCreationFormData] = useState(new FormData())
  const [imageAnnotationCreationFormDataComplete, setImageAnnotationCreationFormDataComplete] = useState(false)
  // console.log(referenceNote)
  // console.log(formData)
  // console.log(documentFeature)
  // console.log(user)
  
  const params = useParams()

  const handleReferenceDeletion = async (evt) => {
    const data = {document: documentFeature.documentNumber, user: user._id}
    try {
      if (referenceNote) {
        const noteData = {noteId: referenceNote.noteId, userId: user._id}
        await deleteReferenceNote (noteData)
      }
      await removeReferenceDocuments(data)
      await updateProject(project.projNum)
    } catch (error) {
      console.log(error.message)
    }
    
  }

  const checkValidTotalFileSize = (files) => {
    let totalFileSize = 0;
    for (let i = 0; i < files.length; i++) {
      totalFileSize += files[i].size;
    }
    return totalFileSize < 20000000;
  }

  const checkValidFileTypes = (files) => {
    const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    for (let i = 0; i < files.length; i++) {
      if (!validFileTypes.includes(files[i].type)) {
        return false;
      }
    }
    return true;
  }

  function handleImageAnnotationCreationFormChange(evt) {
    if (evt.target.name === 'feature') {
      imageAnnotationCreationFormData.delete('feature')
      imageAnnotationCreationFormData.append('feature', evt.target.value)
    }
    if (evt.target.name === 'imageAnnotationFile' && evt.target.files[0]) {
      const fileUploadNotification = document.getElementById('fileUploadNotification');
      fileUploadNotification.innerHTML = 'Loading...';
      if (evt.target.files.length!==0 && checkValidTotalFileSize(evt.target.files) && checkValidFileTypes(evt.target.files)) {
        fileUploadNotification.style.opacity = 0.8;
        fileUploadNotification.style.color = '#0A7637';
        fileUploadNotification.style.fontWeight = 'bold';
        fileUploadNotification.innerHTML = 'File added!';
        imageAnnotationCreationFormData.delete('imageAnnotationFile')
        imageAnnotationCreationFormData.append('imageAnnotationFile', evt.target.files[0])
      }else if (evt.target.files.length!==0 && !checkValidTotalFileSize(evt.target.files)) {
        fileUploadNotification.style.opacity = 0.8;
        fileUploadNotification.style.color = '#CA150C';
        fileUploadNotification.style.fontWeight = 'bold';
        fileUploadNotification.innerHTML = 'Error: File exceeds 20MB!';
        imageAnnotationCreationFormData.delete('imageAnnotationFile')
        setImageAnnotationCreationFormDataComplete(false)
      } else if (evt.target.files.length!==0 && !checkValidFileTypes(evt.target.files)) {
        fileUploadNotification.style.opacity = 0.8;
        fileUploadNotification.style.color = '#CA150C';
        fileUploadNotification.style.fontWeight = 'bold';
        fileUploadNotification.innerHTML = 'Error: Invalid file type(s) added!';
        imageAnnotationCreationFormData.delete('imageAnnotationFile')
        setImageAnnotationCreationFormDataComplete(false)
      }
    }
    if (imageAnnotationCreationFormData.get('feature') && imageAnnotationCreationFormData.get('imageAnnotationFile')) {
      setImageAnnotationCreationFormDataComplete(true)
    }
  }

  const handleImageAnnotationCreation = async (evt) => {
    setIsSubmitting(true)
    evt.preventDefault()
    for (var pair of Object.entries(defaultFormData)) {
      imageAnnotationCreationFormData.delete(pair[0])
      imageAnnotationCreationFormData.append(pair[0], pair[1]);
    }
    // const formData = Object.fromEntries(imageAnnotationCreationFormData.entries())
    // console.log(formData)
    try {
      fetch(`/api/projects/${project.projNum}/uploadNPLReferenceImage`, {
        method: 'POST',
        body: imageAnnotationCreationFormData,
      })
      .then((res) => res.json())
      .then((result) => {
        formNotificationStyle2('imageAnnotationCreationNotification', 'Annotation Created!', '#0A7637', 1)
        updateProject(project.projNum)
        // clear the form
        document.getElementById(`createImageAnnotationForm${num}`).reset()
        document.getElementById('fileUploadNotification').innerHTML = 'No files selected!'
        setImageAnnotationCreationFormDataComplete(false)
        setIsSubmitting(false)
      })
    } catch (error) {
      console.log(error.message)
      setIsSubmitting(false)
      setImageAnnotationCreationFormDataComplete(false)
      formNotificationStyle2('imageAnnotationCreationNotification', 'Error: Annotation not created!', '#CA150C', 1)
    }
  }

  const handleDropdownClick = (evt) => {
    // prevent dropdown from closing when clicked
    evt.stopPropagation()
  }

  const createNewReferenceNote = async (evt) => {
    evt.preventDefault()
    const data = {
      ...formData,
      userId: user._id,
      project: project._id,
      document: documentFeature.documentNumber,
      noteText: 'New Note...'
    }
    try {
      await createReferenceNote(data)
      await updateProject(project.projNum)
      setTimeout(() => {
        // Open the dropdown
        document.getElementById(`reference${num}Notes`).click()
        document.getElementById(`reference${num}NoteText`).focus()
      }, 100)
    } catch (error) {
      console.log(error.message)
    }
    
  }

  const handleReferenceNoteChange = (evt) => {
    setChangesMade(true)
    if (document.getElementById(`noteSaveButton${num}`)) {
      document.getElementById(`noteSaveButton${num}`).disabled = false
    }
    const newFormData = {
      ...formData,
      [evt.target.name]: evt.target.value,
    };
    setFormData(newFormData);
  }

  const updateNote = async (evt) => {
    evt.preventDefault()
    if (!formData[`reference${num}Note`]) {
      return
    }
    const data = {
      ...formData,
      noteId: referenceNote.noteId,
      noteText: formData[`reference${num}Note`],
      userId: user._id,
    }
    // console.log(data)
    try {
      await updateReferenceNote(data)
      await updateProject(project.projNum)
      document.getElementById(`noteSaveButton${num}`).disabled = true
    } catch (error) {
      console.log(error.message)
    }
    
  }

  const deleteNote = async (evt) => {
    evt.preventDefault()
    const data = {
      noteId: referenceNote.noteId,
      userId: user._id
    }
    // console.log(data)
    try {
      await deleteReferenceNote(data)
      await updateProject(project.projNum)
    } catch (error) {
      console.log(error.message)
    }
  } 

  useEffect(() => {
    if (referenceNotes) {
      const note = referenceNotes.filter(note => note.document === documentFeature.documentNumber)
      // console.log(note)
      if (note.length > 0) {
        setReferenceNote({
          noteId: note[0]._id,
          noteText: note[0].noteText,
          lastUpdatedBy: capitalizeFirstLetter(note[0].lastUpdatedBy)
        })
        setFormData({
          ...formData,
          [`reference${num}Note`]: note[0].noteText
        })
      } else {  
        setReferenceNote()
      }
    }
    // eslint-disable-next-line
  },[referenceNotes, documentFeature.documentNumber])

  return (
    <div className='DocumentFeature'>
      <div className='documentFeatureContainer'>
        <div className='top'>
          <div className='section1'>
            <span className='section1Ref'>{num}</span>
            <span className='section1RefNum'>{`Reference ${num}`}</span>
          </div>
          <div className='documentActions'>
            { params.projectNumber &&
            <>
              { (project && 
                ((project.status === 'inProgress') || 
                (project.status === 'inReview' && (user.userType==='admin'||user.userType === 'manager'))) &&
                documentFeature.features[0].classification !== 'PD')
                &&
              <div className='createImageAnnotation'>
                <button className='createImageAnnotationButton' title='Create Image Annotation' data-bs-toggle="modal" data-bs-target={`#createImageAnnotation${num}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" fill="#0A7637" className="bi bi-upload" viewBox="0 0 16 16">
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
                </svg>
                  <span>
                    &nbsp;&nbsp;Add Image Annotation
                  </span>
                </button>
                <div className="modal fade" id={`createImageAnnotation${num}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`createImageAnnotationHeader${num}`} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                              <svg xmlns="http://www.w3.org/2000/svg" width="30" fill="#0A7637" className="bi bi-upload" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
                              </svg>
                              <div className="modal-title" id={`createImageAnnotationHeader${num}`}>
                                  <div>Add Image Annotation for Reference </div>
                                  <span className='refNumber'>
                                  {num}
                                  </span>
                              </div>
                              <div className='modalRefTitle'>
                                  {documentFeature.features[0].documentTitle}
                              </div>
                            </div>
                            <div className="modal-body">
                              <form id={`createImageAnnotationForm${num}`} onSubmit={handleImageAnnotationCreation} className='' encType='multipart/form-data' >
                                  <div className='mb-3'>
                                    <label htmlFor="imageAnnotation" className="form-label mb-0">Select a project element</label>
                                    <select className='form-select' id='imageAnnotation' name='feature' defaultValue='' onChange={handleImageAnnotationCreationFormChange}>
                                      <option value='' disabled>Select a project element</option>
                                      {
                                        project.features.map((feature, index) => (
                                          <option key={index} value={feature.featureNum}>{`Element ${feature.featureNum}`}</option>
                                        ))
                                      }
                                    </select>
                                  </div>
                                  <div>
                                      <label htmlFor="imageAnnotationFile">Image <small>(jpg, jpeg, png)</small></label>
                                      <input onChange={handleImageAnnotationCreationFormChange} className='form-control' type="file" name="imageAnnotationFile" id="imageAnnotationFile" required />
                                  </div>
                                  <p id='fileUploadNotification'className='fileUploadNotification text-center'>No files selected!</p>
                              </form>
                            </div>
                            { isSubmitting &&
                            <div className='loading'>
                              <div className="spinner-border" role="status"></div>
                            </div>
                            }
                            <div className="modal-footer">
                                <button type="button" className="cancel" data-bs-dismiss="modal" disabled={isSubmitting}>Exit</button>
                                <button onClick={handleImageAnnotationCreation} type="submit" form={`createImageAnnotationForm${num}`} className="createImageAnnotationSubmit" disabled={!imageAnnotationCreationFormDataComplete || isSubmitting}>Create Annotation</button>
                            </div>
                            <div id='imageAnnotationCreationNotification' className='text-center pb-2'></div>
                        </div>
                    </div>
                </div>
              </div>
              }
              { (project && project.status !== 'completed' && !referenceNote) &&
                <button onClick={createNewReferenceNote} className='addReferenceNoteButton' id={`reference${num}NoteCreation`} data-title={`Add Reference ${num} Notes`}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5F6B7A" className="bi bi-sticky-fill" viewBox="0 0 16 16">
                    <path d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1zm6 8.5a1 1 0 0 1 1-1h4.396a.25.25 0 0 1 .177.427l-5.146 5.146a.25.25 0 0 1-.427-.177z"/>
                  </svg>
                  <span>
                    &nbsp;&nbsp;Add Notes
                  </span>
                </button>
              }
              { (project && project.status !== 'completed' && referenceNote) &&
              <div className='referenceNotes dropup'>
                <button className='viewReferenceNoteButton dropdown' id={`reference${num}Notes`} data-title={`Reference ${num} Notes`} data-bs-toggle="dropdown" aria-expanded="false">
                  <svg width="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 0.800781C14.482 0.800781 16.8351 1.66187 18.8839 3.25605C20.9327 4.8386 22.6771 7.15424 23.9298 10.0517C24.0234 10.2728 24.0234 10.5288 23.9298 10.7382C21.4244 16.5331 16.9639 20.0008 12 20.0008H11.9883C7.0361 20.0008 2.57561 16.5331 0.0702439 10.7382C-0.0234146 10.5288 -0.0234146 10.2728 0.0702439 10.0517C2.57561 4.25678 7.0361 0.800781 11.9883 0.800781H12ZM12 5.74624C9.41268 5.74624 7.31707 7.82914 7.31707 10.4008C7.31707 12.9608 9.41268 15.0437 12 15.0437C14.5756 15.0437 16.6712 12.9608 16.6712 10.4008C16.6712 7.82914 14.5756 5.74624 12 5.74624ZM12.0014 7.48913C13.6053 7.48913 14.9165 8.7924 14.9165 10.3982C14.9165 11.9924 13.6053 13.2957 12.0014 13.2957C10.3858 13.2957 9.07458 11.9924 9.07458 10.3982C9.07458 10.2004 9.09799 10.0142 9.13311 9.82804H9.19165C10.4912 9.82804 11.5448 8.80404 11.5916 7.52404C11.7204 7.50077 11.8609 7.48913 12.0014 7.48913Z" fill="#5F6B7A"/>
                  </svg>
                  <span>
                    &nbsp;&nbsp;View Notes
                  </span>
                </button>
                <div onClick={handleDropdownClick} className="dropdown-menu notesDropDown" aria-labelledby={`dropdownMenuButton${num}`}>
                  <div className='noteHeader'>
                    <svg width="40" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_296_4756)">
                        <rect x="2" y="1.86328" width="48" height="48" rx="10" fill="white"/>
                        <rect x="2.5" y="2.36328" width="47" height="47" rx="9.5" stroke="#1A3860" strokeOpacity="0.08"/>
                      </g>
                      <rect x="12" y="17" width="26" height="3.25" rx="1.625" fill="#192434"/>
                      <rect x="15.25" y="23.5" width="19.5" height="3.25" rx="1.625" fill="#192434"/>
                      <rect x="20.668" y="31.083" width="8.66667" height="3.25" rx="1.625" fill="#192434"/>
                    </svg>
                    <span>
                      &nbsp;&nbsp;&nbsp;Add Notes for Reference {num}
                    </span>
                  </div>
                  <div className='noteContainer'>
                    <div className='noteBody'>
                      <textarea onChange={handleReferenceNoteChange} name={`reference${num}Note`} id={`reference${num}NoteText`} placeholder={`Add note for reference ${num}...`} value={formData[`reference${num}Note`]}></textarea>
                      <div className='lastUpdatedBy'>
                        <small>Last updated by {referenceNote.lastUpdatedBy}</small>
                      </div>
                    </div>
                  </div>
                  <div className='noteActionButtons'>
                    { changesMade &&
                    <button onClick={updateNote} className={`noteSaveButton`} id={`noteSaveButton${num}`}>Save</button>
                    }
                    <button onClick={deleteNote} className='noteDeleteButton'>Delete</button>
                  </div>
                  
                </div>
              </div>
              }
              { (project && ((project.status === 'inProgress') || 
                (project.status === 'inReview' && (user.userType==='admin'||user.userType === 'manager'))))
                &&
              <div className='referenceDelete'>
                <button className='referenceDeleteButton' title='delete' data-bs-toggle="modal" data-bs-target={`#deleteReference${num}`}>
                  <svg width="15" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7101 7.7017C17.9316 7.7017 18.1326 7.80175 18.2914 7.9708C18.4395 8.15135 18.514 8.3756 18.4924 8.61249C18.4924 8.69069 17.9003 16.4416 17.562 19.7041C17.3502 21.7063 16.1032 22.9218 14.2327 22.9552C12.7944 22.9885 11.3885 23 10.0042 23C8.53458 23 7.09736 22.9885 5.70229 22.9552C3.89442 22.9103 2.64632 21.6729 2.44532 19.7041C2.09736 16.4301 1.51599 8.69069 1.50519 8.61249C1.49438 8.3756 1.56786 8.15135 1.71699 7.9708C1.86395 7.80175 2.07575 7.7017 2.29836 7.7017H17.7101ZM12.2941 0C13.2764 0 14.1539 0.709543 14.4078 1.72153L14.5894 2.56102C14.7363 3.24527 15.3091 3.72941 15.9682 3.72941H19.2079C19.6402 3.72941 20 4.10086 20 4.5735V5.0105C20 5.47165 19.6402 5.85459 19.2079 5.85459H0.793171C0.359844 5.85459 0 5.47165 0 5.0105V4.5735C0 4.10086 0.359844 3.72941 0.793171 3.72941H4.03285C4.69094 3.72941 5.26367 3.24527 5.41171 2.56217L5.58137 1.77788C5.84504 0.709543 6.71277 0 7.70586 0H12.2941Z" fill="#E21D12"/>
                  </svg>
                  <span>
                    &nbsp;&nbsp;Delete Reference
                  </span>
                </button>
                <div className="modal fade" id={`deleteReference${num}`} data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`deleteReferenceHeader${num}`} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <svg width="35" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M17.7101 7.7017C17.9316 7.7017 18.1326 7.80175 18.2914 7.9708C18.4395 8.15135 18.514 8.3756 18.4924 8.61249C18.4924 8.69069 17.9003 16.4416 17.562 19.7041C17.3502 21.7063 16.1032 22.9218 14.2327 22.9552C12.7944 22.9885 11.3885 23 10.0042 23C8.53458 23 7.09736 22.9885 5.70229 22.9552C3.89442 22.9103 2.64632 21.6729 2.44532 19.7041C2.09736 16.4301 1.51599 8.69069 1.50519 8.61249C1.49438 8.3756 1.56786 8.15135 1.71699 7.9708C1.86395 7.80175 2.07575 7.7017 2.29836 7.7017H17.7101ZM12.2941 0C13.2764 0 14.1539 0.709543 14.4078 1.72153L14.5894 2.56102C14.7363 3.24527 15.3091 3.72941 15.9682 3.72941H19.2079C19.6402 3.72941 20 4.10086 20 4.5735V5.0105C20 5.47165 19.6402 5.85459 19.2079 5.85459H0.793171C0.359844 5.85459 0 5.47165 0 5.0105V4.5735C0 4.10086 0.359844 3.72941 0.793171 3.72941H4.03285C4.69094 3.72941 5.26367 3.24527 5.41171 2.56217L5.58137 1.77788C5.84504 0.709543 6.71277 0 7.70586 0H12.2941Z" fill="#E21D12"/>
                                </svg>
                                <div className="modal-title" id={`deleteReferenceHeader${num}`}>
                                    <div>Delete Reference</div>
                                    <span className='refNumber'>
                                    {num}
                                    </span>
                                </div>
                                <div className='modalRefTitle'>
                                    {documentFeature.features[0].documentTitle}
                                </div>
                            </div>
                            <div className="modal-body">
                                <p className='warningMessage errorText'>Once deleted, a reference cannot be recovered</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="cancel" data-bs-dismiss="modal">Cancel</button>
                                <button onClick={handleReferenceDeletion} type="button" className="submit" data-bs-dismiss="modal">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              }
            </>
            }
          </div>
        </div>
        <div className='documentData'>
          { documentFeature.features[0].documentTitle &&
          <div className='documentHead'>
            <div className='documentTitle'>{documentFeature.features[0].documentTitle}</div>
            { documentFeature.features[0].classification === 'PD' ?
            <>
            <div className='documentNumber'>
              <span className='label'>Document:</span>
              <Link className='text-black' to={`https://patents.google.com/patent/${documentFeature.documentNumber}/en?oq=${documentFeature.documentNumber}`} target='_blank'>
                {documentFeature.documentNumber}
              </Link>
            </div>
            <div className='assignees'>
              <span className='label'>Assignee(s):</span>
              <span>
                {documentFeature.features[0].assignees}
              </span>
            </div>
            </>
            :
            <div className='documentNumber'>
              <span className='label'>Document:</span>
              <Link className='text-black' to={documentFeature.documentNumber} target='_blank'>
                {documentFeature.documentNumber}
              </Link>
            </div>
            }
            <div className='documentPublicationDate'>
              <span className='label'>Publication Date:</span>
              <span>
                {documentFeature.features[0].documentPublicationDate}
              </span>
            </div>
          </div>
          }
          
        </div>
      </div>
      <div className='documentFeatures'>
      { documentFeature.features ?
          documentFeature.features.map((feature, idx) => (
              <DocumentFeatureItem 
              user={user} 
              project={project} 
              feature={feature} 
              key={idx} 
              documentFeatureNum={num} 
              index={idx} 
              removeTextAnnotation={removeTextAnnotation} 
              removeImageAnnotation={removeImageAnnotation} 
              updateProject={updateProject} 
              />
          ))
          :
          <p className='text-center'>No Features</p>
      }
      </div>
    </div>
  )
}
